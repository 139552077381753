import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  children: PropTypes.node.isRequired
};

const DialogOptions = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string
};

const PromiseInfo = {
  resolve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
};

const ShowDialogHandler = PropTypes.func.isRequired;
/**
 * @typedef {Object} Options
 * @property {string} options.title - Dialog title.
 * @property {string=} options.message - Dialog message (optional).
 * @property {boolean=} options.showCancel - Show Cancel (optional).
 * @property {string=} options.customCancelText - customCancelText (optional).
 * @property {string=} options.customApproveText - customApproveText (optional).
 * @property {any=} options.childrens - customApproveText (optional).
 */
/**
 * Function to show a dialog with the specified options.
 *
 * @callback ShowDialogHandler
 * @param {Options} options - Dialog options.
 * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating the user's confirmation.
 */

/**
 * @type {React.Context<ShowDialogHandler>}
 */
const DialogContext = createContext(ShowDialogHandler);

const DialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    title: ''
  });
  const [promiseInfo, setPromiseInfo] = useState();

  /**
   * Function to show a dialog with the specified options.
   *
   * @param {Object} options - Dialog options.
   * @param {string} options.title - Dialog title.
   * @param {string=} options.message - Dialog message (optional).
   * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating the user's confirmation.
   */
  const showDialog = options => {
    return new Promise((resolve, reject) => {
      setPromiseInfo({ resolve, reject });
      setOptions(options);
      setOpen(true);
    });
  };

  const handleConfirm = () => {
    setOpen(false);
    promiseInfo?.resolve(true);
    setPromiseInfo(undefined);
  };

  const handleCancel = () => {
    setOpen(false);
    promiseInfo?.resolve(false);
    setPromiseInfo(undefined);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{options.title}</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          {options.message && (
            <DialogContentText>
              {typeof options?.message === 'string' ? (
                options?.message
                  ?.split('\n')
                  ?.map(s => <Typography>{s}</Typography>)
              ) : (
                <Typography>{options?.message}</Typography>
              )}
            </DialogContentText>
          )}
          {!!options.childrens && options.childrens}
        </DialogContent>
        <DialogActions>
          {options?.showCancel && (
            <Button onClick={handleCancel}>
              <FormattedMessage id={options.customCancelText ?? 'cancel'} />
            </Button>
          )}
          <Button variant="contained" onClick={handleConfirm}>
            <FormattedMessage id={options.customApproveText ?? 'approve'} />
          </Button>
        </DialogActions>
      </Dialog>

      <DialogContext.Provider value={showDialog}>
        {children}
      </DialogContext.Provider>
    </>
  );
};

DialogProvider.propTypes = propTypes;
/**
 * Custom hook to access the showDialog function from the DialogContext.
 *
 * @returns {ShowDialogHandler} - The showDialog function.
 */
export const useDialog = () => {
  return useContext(DialogContext);
};

export default DialogProvider;
