import styled from '@emotion/styled';
import LockIcon from '@mui/icons-material/Lock';
import { Alert } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Divider,
  Link,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { isUrlDefaultDomain } from 'src/utils/utils';
import LanguageButtons from '../../../components/LanguageButtons';
import Page from '../../../components/Page';
import LoginForm from './LoginForm';
import AuthPageTopBar from '../../../components/AuthPageTopBar';

const PageRoot = styled(Page)(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.background.dark,
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  paddingBottom: 80,
  paddingTop: 80
}));

const StyledCard = styled(Card)(({ theme }) => ({
  overflow: 'visible',
  display: 'flex',
  position: 'relative',

  '& > *': {
    flexGrow: 1,
    flexBasis: '50%',
    width: '50%'
  }
}));

const CardContentContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(8, 4, 3, 4)
}));

const CardMediaMedia = styled(CardMedia)(({ theme }) => ({
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

const AvatarIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: colors.green[500],
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  position: 'absolute',
  top: -32,
  left: theme.spacing(3),
  height: 64,
  width: 64
}));

// eslint-disable-next-line react/prop-types
function LoginView({ intl, isAdvertiser = false }) {
  const initialState = {
    error: false,
    errorMessage: ''
  };

  const [errorState, setError] = useState(initialState);
  // eslint-disable-next-line react/prop-types
  const onErrorHandler = (
    errorMessage = intl.formatMessage({ id: 'general_error' })
  ) => {
    setError({
      error: true,
      errorMessage
    });
  };

  const isAllowAdvertiserRegister = useSelector(
    state => state?.company?.allowAdvertiserRegister
  );

  const isDefaultDomain = useMemo(() => isUrlDefaultDomain(), []);

  return (
    <>
      <AuthPageTopBar />
      <PageRoot maxWidth="sm" title={intl.formatMessage({ id: 'login' })}>
        <Box mb={8} display="flex" alignItems="center">
          {isDefaultDomain ? (
            <Button href="https://www.tracknow.io">
              {intl.formatMessage({ id: 'back_to_home' })}
            </Button>
          ) : null}
        </Box>
        <StyledCard>
          <CardContentContent>
            <AvatarIcon>
              <LockIcon fontSize="large" />
            </AvatarIcon>

            <Typography variant="h2" color="textPrimary">
              <FormattedMessage id="sign_in" />{' '}
              {!isDefaultDomain && (
                <FormattedMessage id={isAdvertiser ? 'advertiser' : 'admin'} />
              )}
            </Typography>
            {isDefaultDomain && (
              <Typography variant="subtitle1" color="textSecondary">
                <FormattedMessage id="sign_in_on_the_internal_platform" />
              </Typography>
            )}
            {/* eslint-disable-next-line consistent-return */}
            {(() => {
              if (errorState.error) {
                return (
                  <Box mt={2}>
                    <Alert severity="error" id="loginErrorMessageWarrper">
                      <p id="loginErrorMessage">{errorState.errorMessage}</p>
                    </Alert>
                  </Box>
                );
              }
            })()}
            <Box mt={3}>
              <LoginForm
                isAdvertiser={isAdvertiser}
                onErrorHandler={message => onErrorHandler(message)}
              />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <LanguageButtons />
            <Box display="flex" justifyContent="space-between">
              {isDefaultDomain ? (
                <Link
                  component={RouterLink}
                  to="/register"
                  variant="body2"
                  color="textSecondary"
                >
                  <FormattedMessage id="create_new_account" />
                </Link>
              ) : (
                <>
                  <Link
                    component={RouterLink}
                    to={!isAdvertiser ? '/adv/login' : '/login'}
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage
                      id={
                        !isAdvertiser ? 'login_as_advertiser' : 'login_as_admin'
                      }
                    />
                  </Link>
                  {isAllowAdvertiserRegister ? (
                    <Link
                      component={RouterLink}
                      to="/adv/registration"
                      variant="body2"
                      color="textSecondary"
                    >
                      <FormattedMessage id="become_an_advertiser" />
                    </Link>
                  ) : null}
                </>
              )}

              <Link
                component={RouterLink}
                to={`/forgotPassword${isAdvertiser ? '?isAdv=1' : ''}`}
                variant="body2"
                color="textSecondary"
                style={{ float: 'right' }}
              >
                <FormattedMessage id="forgot_password" />
              </Link>
            </Box>
          </CardContentContent>
          {/* <CardMediaMedia image="/static/images/auth.png" title="Cover">
          <Typography color="inherit" variant="subtitle1">
          </Typography>
          <Box alignItems="center" display="flex" mt={3}>

            <Box ml={3}>
              <Typography color="inherit" variant="body1">
              </Typography>
              <Typography color="inherit" variant="body2">
              </Typography>
            </Box>
          </Box>
        </CardMediaMedia> */}
        </StyledCard>
      </PageRoot>
    </>
  );
}

LoginView.propTypes = {
  intl: PropTypes.any
};

export default injectIntl(LoginView);
