import ApiPaths from '../utils/ajaxUtils';
import { deleteApiCall, get, post, put } from '../utils/axios';
import { rebuildBrandingBanner, rebuildBrandingLogo } from '../utils/utils';
import { COMPANY_BRANDING } from './companyActions';

// eslint-disable-next-line import/prefer-default-export
export const getCurrentBrandingRedux = (refresh = false) => {
  const request = get(`${ApiPaths.companyActions.branding}/current`);
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_BRANDING,
            payload: response.data
          });
          refresh && window.location.reload();
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
};

export const getCurrentBranding = async () => {
  const request = await get(`${ApiPaths.companyActions.branding}/current`);
  return request?.data;
};

export const getTrackingDomainsOptions = async () => {
  const request = await get(
    `${ApiPaths.companyActions.trackingDomains}/options`
  );
  return request?.data;
};

export const getTrackingDomains = async paginationModel => {
  const request = await get(`${ApiPaths.companyActions.trackingDomains}`, {
    paginationModel
  });
  return request?.data;
};

export const createCompanyLogo = async data => {
  const response = await post(
    `${ApiPaths.companyActions.branding}/logo`,
    rebuildBrandingLogo(data)
  );
  return response?.data;
};

export const updateCompanyLogo = async data => {
  const response = await put(
    `${ApiPaths.companyActions.branding}/logo`,
    rebuildBrandingLogo(data)
  );
  return response?.data;
};

export const resetCompanyLogo = async () => {
  const response = await deleteApiCall(
    `${ApiPaths.companyActions.branding}/logo`
  );
  return response?.data;
};

export const createCompanyBanner = async data => {
  const response = await post(
    `${ApiPaths.companyActions.branding}/banner`,
    rebuildBrandingBanner(data)
  );
  return response?.data;
};

export const updateCompanyBanner = async data => {
  const response = await put(
    `${ApiPaths.companyActions.branding}/banner`,
    rebuildBrandingBanner(data)
  );
  return response?.data;
};

export const resetCompanyBanner = async () => {
  const response = await deleteApiCall(
    `${ApiPaths.companyActions.branding}/banner`
  );
  return response?.data;
};

export const createCompanyFavIcon = async data => {
  const response = await post(
    `${ApiPaths.companyActions.branding}/favIcon`,
    rebuildBrandingLogo(data, 'brandFavIcon')
  );
  return response?.data;
};

export const updateCompanyFavIcon = async data => {
  const response = await put(
    `${ApiPaths.companyActions.branding}/favIcon`,
    rebuildBrandingLogo(data, 'brandFavIcon')
  );
  return response?.data;
};

export const resetCompanyFavIcon = async () => {
  const response = await deleteApiCall(
    `${ApiPaths.companyActions.branding}/favIcon`
  );
  return response?.data;
};

export const createCustomDomain = async customDomain => {
  const response = await post(
    `${ApiPaths.companyActions.branding}/custom-domain`,
    null,
    customDomain
  );
  return response?.data;
};

export const updateCustomDomain = async customDomain => {
  const response = await put(
    `${ApiPaths.companyActions.branding}/custom-domain`,
    null,
    customDomain
  );
  return response?.data;
};

export const updateDomainAlias = async customDomain => {
  const response = await put(
    `${ApiPaths.companyActions.branding}/domain-alias`,
    null,
    customDomain
  );
  return response?.data;
};

export const updateSeo = async params => {
  const response = await put(
    `${ApiPaths.companyActions.branding}/seo`,
    null,
    params
  );
  return response?.data;
};

export const updateCustomColors = async (
  primary,
  secondary,
  primaryDark,
  secondaryDark
) => {
  const response = await put(
    `${ApiPaths.companyActions.branding}/custom-colors`,
    null,
    { primary, secondary, primaryDark, secondaryDark }
  );
  return response?.data;
};

export const updateCustomScript = async tagManagerId => {
  const response = await post(
    `${ApiPaths.companyActions.branding}/custom-script`,
    { tagManagerId }
  );
  return response?.data;
};

export const updateCustomHomePage = async html => {
  const response = await post(
    `${ApiPaths.companyActions.branding}/custom-homepage`,
    { html }
  );
  return response?.data;
};

export const resetCustomDomain = async type => {
  const response = await put(
    `${ApiPaths.companyActions.branding}/custom-domain/reset`,
    null,
    { type }
  );
  return response?.data;
};

export const getPlatformTranslations = async () => {
  const request = await get(`${ApiPaths.platformTranslations}`);
  return request?.data;
};

export const overrideTranslationKey = async (key, value, lang) => {
  const request = await put(`${ApiPaths.platformTranslations}/update`, {
    key,
    value,
    lang
  });
  return request?.data;
};
