import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import Label from 'src/components/Label';

export default function AdminBadge() {
  const isAdmin = useMemo(() => {
    try {
      return localStorage?.getItem('accessToken')
        ? jwtDecode(localStorage?.getItem('accessToken'))?.isAdmin
        : false;
    } catch (error) {
      return false;
    }
  }, [localStorage?.getItem('accessToken')]);

  return isAdmin ? (
    <Label icon={'ri:admin-line'} color={'warning'}>
      Admin
    </Label>
  ) : null;
}
