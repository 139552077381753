import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getCompanyInitialData } from 'src/actions/configActions';
import { accountSelector, domainSelector } from '../../selectors/selectors';
import authService from '../../services/authService';

function AuthGuard({ children }) {
  const account = useSelector(accountSelector);
  const { isUnauthorized } = useSelector(state => state.userInterface);
  const domain = useSelector(domainSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!domain || !domain?.domainType) {
      dispatch(getCompanyInitialData());
    }
  }, [domain]);

  const accessToken = authService.getAccessToken();
  if (accessToken == null) {
    authService.logout();
    return <Redirect to="/login" />;
  }
  const accessTokenValid = !!accessToken && accessToken !== '';

  if (!accessTokenValid || !account.user) {
    return <Redirect to="/login" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
