import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import useSettings from 'src/hooks/useSettings';
import { setMomentDefaults } from 'src/utils/utils';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin]
});

function RTL({ children }) {
  const { settings } = useSettings();

  const timeFormat = useSelector(
    state => state.account?.user?.personalization?.timeFormat
  );

  const timeZone = useSelector(
    state => state.account?.user?.personalization?.timeZone
  );

  useEffect(() => {
    setMomentDefaults(timeZone, timeFormat, settings?.lang);
  }, [timeFormat, timeZone, settings]);

  const isRtl = useMemo(() => settings?.direction == 'rtl', [
    settings?.direction
  ]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isRtl ? (
        <>
          <Helmet
            htmlAttributes={{
              lang: 'he'
            }}
          ></Helmet>
          <CacheProvider value={cacheRtl}>{children}</CacheProvider>
        </>
      ) : (
        children
      )}
    </LocalizationProvider>
  );
}
export default RTL;
