export const PaymentMethodTypesEnum = {
  CUSTOM: { key: 'Custom', value: 0 },
  PAYPAL: { key: 'Paypal', value: 1 },
  BANK_TRANSFER: { key: 'Bank transfer', value: 2 },
  SKRILL: { key: 'Skrill', value: 3 },
  NETELLER: { key: 'Neteller', value: 4 },
  EMAIL: { key: 'Email', value: 5 },
  WISE: { key: 'Wise', value: 6 },
  JSON: { key: 'Json', value: 7 }
};
