import { normalize, schema } from 'normalizr';

const inboxSchema = new schema.Entity(
  'inboxes',
  {},
  {
    idAttribute: entity => `${entity.team}-${entity.name}`
  }
);

const inboxArraySchema = new schema.Array(inboxSchema);

const userSchema = new schema.Entity(
  'users',
  {},
  {
    idAttribute: 'email'
  }
);
const usersArraySchema = new schema.Array(userSchema);

const teamSchema = new schema.Entity('teams', {}, { idAttribute: 'name' });

const invitedMember = new schema.Entity(
  'invitedMembers',
  {},
  { idAttribute: 'email' }
);

const invitedMembersArraySchema = new schema.Array(invitedMember);

const emailSchema = new schema.Entity(
  'mails',
  {},
  {
    idAttribute: 'oid'
  }
);
const contactsSchema = new schema.Entity(
  'contacts',
  {},
  {
    idAttribute: 'emailAddress'
  }
);

const statusSchema = new schema.Entity('statuses');

const statusesSchema = new schema.Array(statusSchema);

const filterSchema = new schema.Entity(
  'filters',
  {},
  { idAttribute: 'filterNum' }
);

const filtersSchema = new schema.Array(filterSchema);

const tagSchema = new schema.Entity('tags', {}, { idAttribute: 'name' });

const tagsSchema = new schema.Array(tagSchema);

invitedMember.define({ intendedTeam: teamSchema });

teamSchema.define({
  inboxes: inboxArraySchema,
  invitedMembers: invitedMembersArraySchema,
  teamMembers: usersArraySchema
});

userSchema.define({
  teams: [teamSchema],
  followedInboxes: inboxArraySchema
});

const emailArray = new schema.Array(emailSchema);
const contactsArray = new schema.Array(contactsSchema);

emailSchema.define({
  fromContacts: contactsArray,
  toContacts: contactsArray,
  ccContacts: contactsArray,
  replyToOid: emailSchema
});

export const normalizeEmail = data => normalize(data, emailSchema);

export const normalizeEmailArray = data => normalize(data, emailArray);

export const normalizeUser = data => normalize(data, userSchema);

export const normalizeTeam = data => normalize(data, teamSchema);

export const normalizeInvitedMemberArray = data =>
  normalize(data, invitedMembersArraySchema);

export const normalizeUsersArray = data => normalize(data, usersArraySchema);

export const normalizeStatusesArray = data => normalize(data, statusesSchema);

export const normalizeFiltersArray = data => normalize(data, filtersSchema);

export const normalizeTagsArray = data => normalize(data, tagsSchema);
