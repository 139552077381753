import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../selectors/selectors';

export const userHasScope = (scope, account) => {
  return (
    !scope ||
    (account?.user && account?.user?.userPermissions == null) ||
    account?.user?.userPermissions?.[scope]
  );
};
function ScopeGuard({ scope, children, ...props }) {
  const account = useSelector(accountSelector);
  if (userHasScope(scope, account)) {
    return React.cloneElement(children, props);
  } else return null;
}

ScopeGuard.propTypes = {
  children: PropTypes.any,
  scope: PropTypes.any
};

export default ScopeGuard;
