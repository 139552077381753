// eslint-disable-next-line import/prefer-default-export
export const UserPermissionsEnum = {
  ADVERTISER: { key: 'advertiser', value: 'advertiser', type: 'read' },
  AFFILIATE_CAMPAIGN: {
    key: 'campaign',
    value: 'affiliateCampaign',
    type: 'read'
  },
  AFFILIATE_COMMISSION: {
    key: 'affiliate_commission',
    value: 'affiliateCommission',
    type: 'read'
  },
  AFFILIATE_PAYOUT: {
    key: 'affiliate_payout',
    value: 'affiliatePayout',
    hiddenForAdvertiser: true,
    type: 'read'
  },
  AFFILIATE_REQUESTS: {
    key: 'affiliates_requests',
    value: 'affiliateRequests',
    type: 'read'
  },
  AFFILIATE_MANAGEMENT: {
    key: 'affiliateManagement',
    value: 'affiliateManagement',
    type: 'read'
  },
  CONVERSIONS: { key: 'conversions', value: 'conversions', type: 'read' },
  STATISTICS: { key: 'statistics', value: 'statistics', type: 'read' },
  CLICKS: { key: 'clicks', value: 'clicks', type: 'read' },
  IMPRESSIONS: { key: 'impressions', value: 'impressions', type: 'read' },
  REFERRALS: {
    key: 'referrals',
    value: 'referrals',
    hiddenForAdvertiser: true,
    type: 'read'
  },
  COMPANY: {
    key: 'company',
    value: 'company',
    hiddenForAdvertiser: true,
    type: 'read'
  },
  PAYOUTS: { key: 'payouts', value: 'payouts', type: 'read' },
  GROUPING: {
    key: 'grouping',
    value: 'grouping',
    type: 'read',
    hiddenForAdvertiser: true
  },
  SETTINGS: {
    key: 'settings',
    value: 'settings',
    hiddenForAdvertiser: true,
    type: 'both'
  },
  BILLING: {
    key: 'billing',
    value: 'billing',
    hiddenForAdvertiser: true,
    type: 'read'
  },
  FINANCIAL: {
    key: 'finances',
    value: 'financial',
    type: 'read',
    hiddenForAdvertiser: true,
    brokerOnly: true
  },
  FINANCIAL_USERS: {
    key: 'financialUsers',
    value: 'financialUsers',
    type: 'read',
    hiddenForAdvertiser: true,
    brokerOnly: true
  },
  TRAFFIC_SOURCES: {
    key: 'trafficSources',
    value: 'trafficSources',
    type: 'read'
  },
  FINANCIAL_ACCOUNTS: {
    key: 'financial_accounts',
    value: 'financialAccounts',
    type: 'read',
    hiddenForAdvertiser: true,
    brokerOnly: true
  },
  FINANCIAL_TRANSACTIONS: {
    key: 'financial_transactions',
    value: 'financialTransactions',
    type: 'read',
    brokerOnly: true
  },
  LOGS: { key: 'logs', value: 'logs', type: 'read' },
  AUDIT: {
    key: 'audit',
    value: 'audit',
    hiddenForAdvertiser: true,
    type: 'read'
  },
  LEADS: {
    key: 'leads',
    value: 'leads',
    type: 'read'
  },
  FINANCIAL_REPORTS: {
    key: 'reports',
    value: 'financialReports',
    type: 'read'
  },
  API_KEYS: {
    key: 'apiKeys',
    value: 'apiKeys',
    type: 'both'
  },
  CLICK_LOGS: { key: 'clickLogs', value: 'clickLogs', type: 'read' },
  POSTBACK_LOGS: { key: 'postback', value: 'postbackLogs', type: 'read' },
  ADVERTISER_W: { key: 'advertiser', value: 'advertiserWrite', type: 'write' },
  MANAGE_ALL_AFFILIATES: {
    key: 'manageAllAffiliates',
    value: 'manageAllAffiliates',
    type: 'both',
    hiddenForAdvertiser: true,
    hiddenValue: true
  },
  AFFILIATE_CAMPAIGN_W: {
    key: 'campaign',
    value: 'affiliateCampaignWrite',
    type: 'write'
  },
  AFFILIATE_PAYOUT_W: {
    key: 'affiliatePayout',
    value: 'affiliatePayoutWrite',
    type: 'write'
  },
  GROUPING_W: {
    key: 'grouping',
    value: 'groupingWrite',
    hiddenForAdvertiser: true,
    type: 'write'
  },
  CONVERSIONS_W: {
    key: 'conversions',
    value: 'conversionsWrite',
    type: 'write'
  },
  COMPANY_W: {
    key: 'company',
    value: 'companyWrite',
    hiddenForAdvertiser: true,
    type: 'write'
  },
  NEWS_W: { key: 'news', value: 'newsWrite', type: 'write' },
  PAYOUTS_W: { key: 'payouts', value: 'payoutsWrite', type: 'write' },
  FINANCIAL_W: {
    key: 'finances',
    value: 'financialWrite',
    type: 'write',
    hiddenForAdvertiser: true,
    brokerOnly: true
  },
  FINANCIAL_USERS_W: {
    key: 'financialUsers',
    value: 'financialUsersWrite',
    type: 'write',
    hiddenForAdvertiser: true,
    brokerOnly: true
  },
  FINANCIAL_ACCOUNTS_W: {
    key: 'financialAccounts',
    value: 'financialAccountsWrite',
    type: 'write',
    hiddenForAdvertiser: true,
    brokerOnly: true
  },
  FINANCIAL_TRANSACTIONS_W: {
    key: 'financialTransactions',
    value: 'financialTransactionsWrite',
    hiddenForAdvertiser: true,
    type: 'write',
    brokerOnly: true
  },
  AFFILIATE_REQUESTS_W: {
    key: 'affiliatesRequests',
    value: 'affiliateRequestsWrite',
    type: 'write'
  },
  AFFILIATE_MANAGEMENT_W: {
    key: 'affiliateManagement',
    value: 'affiliateManagementWrite',
    type: 'write'
  },
  TRAFFIC_SOURCES_W: {
    key: 'trafficSources',
    value: 'trafficSourcesWrite',
    type: 'write'
  },
  LEADS_W: {
    key: 'leads',
    value: 'leadsWrite',
    type: 'write'
  }
};
