import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { IconButton, SvgIcon } from '@mui/material';

import { updateMerchantTheme } from 'src/actions/userActions';
import { THEMES } from 'src/constants';
import useSettings from 'src/hooks/useSettings';

export default function ThemeModeToggle() {
  const { settings, saveSettings } = useSettings();

  return (
    <IconButton
      color="inherit"
      onClick={() =>
        updateMerchantTheme(
          settings?.theme == THEMES.LIGHT.name
            ? THEMES.ONE_DARK.name
            : THEMES.LIGHT.name,
          saveSettings
        )
      }
      size="large"
    >
      <SvgIcon fontSize="small">
        {settings?.theme == THEMES.LIGHT.name ? (
          <DarkModeOutlinedIcon />
        ) : (
          <LightModeOutlinedIcon />
        )}
      </SvgIcon>
    </IconButton>
  );
}
