import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Label from 'src/components/Label';
import { MEMBERSHIP_PLANS_TO_COLORS } from 'src/components/guards/MembershipGuard';
import ScopeGuard from 'src/components/guards/ScopeGuard';
import { companySelector } from 'src/selectors/selectors';
import { UserPermissionsEnum } from 'src/utils/enums/UserPermissionsEnum';

export default function MembershipInfo(props) {
  const { membership } = useSelector(companySelector);
  const intl = useIntl();
  const title = membership?.planTitle;

  return (
    <>
      <ScopeGuard scope={UserPermissionsEnum.BILLING.value}>
        <>
          <Typography variant="body2" color="textSecondary">
            {intl.formatMessage({
              id: 'your_membership_tier'
            })}
            <br />
            <RouterLink to="/app/management/billing">
              <Label
                color={
                  MEMBERSHIP_PLANS_TO_COLORS?.[membership?.type] ?? 'primary'
                }
                isPointer
              >
                {title}
              </Label>
            </RouterLink>
          </Typography>
        </>
      </ScopeGuard>
    </>
  );
}
