import React from 'react';
import { useSelector } from 'react-redux';
import { accountSelector } from 'src/selectors/selectors';
import { permissionsEnum, userRolesEnum } from './roles';

export default class PermissionsUtils extends React.Component {
  static hasPermission({ action, role }) {
    const { user } = useSelector(accountSelector);
    if (user) {
      role = role || user.role;
      if (userRolesEnum[role].indexOf(permissionsEnum.All) !== -1) return true;
      if (userRolesEnum[role].indexOf(permissionsEnum.NOTING) !== -1)
        return false;
      return userRolesEnum[role].indexOf(action) > -1;
    }
    return false;
  }

  render() {
    return null;
  }
}
