import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { brandingSelector } from 'src/selectors/selectors';
import { getCurrentBrandingRedux } from '../actions/brandingActions';
import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';

function Logo({ color = null, isNegativeMode = false, onlyTracknow  = false, ...props}) {
  const dispatch = useDispatch();
  const branding = useSelector(brandingSelector);
  const companyHasBranding = useSelector(
    state => state?.company?.companyHasBranding
  );
  const baseImagesUrl = process.env.REACT_APP_BRAND_LOGO_BASE_URL;

  const config = useSelector(state => state?.config);
  const isMobileNavOpen = config?.isMobileNavOpen;

  const fetchData = async () => {
    if (companyHasBranding && !branding) {
      dispatch(getCurrentBrandingRedux());
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { settings } = useSettings();
  if (!color)
    color =
      (isNegativeMode || isMobileNavOpen) &&
      settings.theme === THEMES.LIGHT.name
        ? 'dark'
        : 'white';

  const defaultLogo = `/static/logos/tracknow-logo-${color}.webp`;
  const imgUrl = !onlyTracknow && branding?.brandLogoFilePath
    ? `${baseImagesUrl}${branding?.brandLogoFilePath}`
    : defaultLogo;
  return (
    <img
      alt="Logo"
      src={imgUrl}
      style={{ maxWidth: 200, maxHeight: 50 }}
      {...props}
    />
  );
}

export default Logo;
