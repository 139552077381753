import { AppBar, Badge, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import Iconify from 'src/components/iconify';
import { THEMES } from 'src/constants';
import Account from './Account';
import AdminBadge from './AdminBadge';
import CustomClock from './Clock';
import LanguagePopover from './LanguagePopover';
import ThemeModeToggle from './ThemeModeToggle';
import { accountSelector } from '../../../selectors/selectors';
import authService from '../../../services/authService';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT.name
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK.name
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    height: 65
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  centerLogo: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  }
}));

function TopBar({ className, onMobileNavOpen, centerLogo = false, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const notifications = useSelector(state => state?.company?.notification);
  const { user } = useSelector(accountSelector);
  const accessToken = authService.getAccessToken();
  const accessTokenValid = !!accessToken && accessToken !== '';

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {!!user && accessTokenValid && (
          <Hidden lgUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onMobileNavOpen}
              size="large"
            >
              <Badge
                color="warning"
                badgeContent={
                  notifications
                    ? Object.values(notifications ?? {})?.reduce((sum, obj) => {
                        return sum + (obj || 0); // Use 0 if notification is undefined or null
                      }, 0)
                    : 0
                }
              >
                <Iconify icon="material-symbols:menu" />
              </Badge>
            </IconButton>
          </Hidden>
        )}
        <Hidden lgDown={!centerLogo}>
          <RouterLink
            to="/"
            className={clsx({ [classes.centerLogo]: centerLogo })}
          >
            <Logo />
          </RouterLink>
        </Hidden>
        <AdminBadge />
        <Box ml={2} flexGrow={1} />
        {!!user && accessTokenValid && (
          <>
            <Hidden lgDown>
              <CustomClock />
            </Hidden>
            {/* <Search /> todo need to be  implemented */}
            {/* <Contacts /> todo need to be  implemented */}
            {/* <Notifications /> todo need to be  implemented  */}
            {/* <Settings /> */}
            <ThemeModeToggle />
            <IconButton
              color="inherit"
              onClick={() => history.push('/app/management/settings')}
              size="large"
            >
              <Iconify icon="material-symbols:settings" />
            </IconButton>{' '}
          </>
        )}
        <Hidden lgDown={centerLogo}>
          <LanguagePopover />
        </Hidden>
        {!!user && accessTokenValid && (
          <Box ml={2}>
            <Account />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
