import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ListSubheader, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchBar from 'src/components/SearchBar';
import ScopeGuard from 'src/components/guards/ScopeGuard';
import SocialGuard from 'src/components/guards/SocialGuard';
import Iconify from 'src/components/iconify/Iconify';
import { useSelector } from 'react-redux';
import { companySelector } from '../../../selectors/selectors';
import SiteTypeGuard from '../../../components/guards/BrokerGuard';

const drawerWidth = 280;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  height: 'calc(100vh - 65px)',
  overflowX: 'hidden'
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  height: 'calc(100vh - 65px)',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  position: 'sticky',
  padding: theme.spacing(2, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    position: 'fixed',
    ...openedMixin(theme),
    '& .MuiDrawer-paper': { position: 'fixed', ...openedMixin(theme) }
  }),
  ...(!open && {
    ...closedMixin(theme),
    position: 'fixed',

    '& .MuiDrawer-paper': { position: 'fixed', ...closedMixin(theme) }
  })
}));

const DrawerSpacer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': { ...openedMixin(theme) }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const filterOptions = (options, inputValue) => {
  const escapedInputValue = inputValue
    ? inputValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
    : '';

  const inputRegex = new RegExp(escapedInputValue, 'i');

  // Filter options to exclude the already selected countries
  return options?.filter(option => {
    const optionName = option?.label || '';
    const optionIso3 = option?.value || '';
    // const optionCode = option?.code || '';
    return (
      inputRegex.test(optionName) || inputRegex.test(optionIso3)
      //  ||
      // inputRegex.test(optionCode)
    );
  });
};

const activeStyle = {
  color: theme => theme.palette.secondary.main,
  '& $title': {
    fontWeight: `600 !important`
  },
  '& $icon': {
    color: theme => theme.palette.secondary.main
  }
};

/**
 * A React component that provides a mini variant drawer that can be toggled between open and closed states.
 * The drawer can hold a list of items defined by `subBarItems` prop and the main content is rendered in the `children` prop.
 *
 * @component
 * @example
 * const subBarItems = [
 *  { label: 'Inbox', icon: 'mdi:inbox', onClick: handleInboxClick },
 *  { label: 'Starred', icon: 'mdi:star', onClick: handleStarredClick },
 * ];
 *
 * return (
 *   <MiniDrawer subBarItems={subBarItems}>
 *     <MainContent />
 *   </MiniDrawer>
 * );
 *
 * @param {Object} props - The component props.
 * @param {Object[]} props.subBarItems - An array of items to be displayed in the drawer. Each item is an object with `label`, `icon`, and `onClick` properties.
 * @param {string} props.subBarItems[].label - The label text of the drawer item.
 * @param {string} props.subBarItems[].icon - The icon of the drawer item, passed to the `Iconify` component.
 * @param {boolean} props.subBarItems[].subheader =false - if the item is sub header.
 * @param {function} props.subBarItems[].onClick - The function to call when the drawer item is clicked.
 * @param {string} props.subBarItems[].scope - The scope of the item item, passed to the `ScopeGuard` component.
 * @param {function} props.subBarItems[].socialOnly - use SocialGuard or not.
 * @param {function} props.subBarItems[].siteType - use SocialGuard or not.
 * @param {boolean} props.searchable - Determent if search bar is showing defualt false.
 * @param {int} props.activeValue - Determent the index of active tab.
 * @param {React.ReactNode} props.children - The main content to be displayed to the right of the drawer.
 * @returns {React.Element} The rendered component.
 */
export default function MiniDrawer({
  subBarItems,
  children,
  searchable,
  activeValue
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [query, setQuery] = React.useState('');
  const inMobile = useMediaQuery(theme => theme.breakpoints.only('xs'), {
    noSsr: true
  });
  const { siteType } = useSelector(companySelector);

  const intl = useIntl();

  const filtered = React.useMemo(() => filterOptions(subBarItems, query), [
    subBarItems,
    query
  ]);

  const handleQueryChange = event => {
    event.persist();
    setQuery(event.target.value);
  };

  React.useEffect(() => {
    setOpen(!inMobile);
  }, [inMobile]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!!subBarItems && (
        <>
          <Drawer
            PaperProps={{ style: { position: 'sticky' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
              container: document.getElementById('drawer-container'),
              style: { position: 'sticky' }
            }}
            variant="permanent"
            open={open}
          >
            <DrawerHeader>
              {open && searchable && (
                <SearchBar
                  placeholder={intl.formatMessage({ id: 'search' })}
                  query={query}
                  handleQueryChange={handleQueryChange}
                />
              )}
              <IconButton
                sx={{
                  transform: `rotateZ(${!open ? 180 : 0}deg)`,
                  transition: theme =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.short
                    })
                }}
                onClick={toggleDrawer}
              >
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <PerfectScrollbar
              style={{ paddingTop: '0px' }}
              options={{ suppressScrollX: true }}
            >
              <List sx={{ pt: 0 }}>
                {filtered?.map(
                  (
                    {
                      label = '',
                      icon = '',
                      onClick = () => {},
                      subheader,
                      scope = null,
                      value = null,
                      socialOnly = false,
                      siteTypes = null
                    },
                    index
                  ) => (
                    <SiteTypeGuard allowedTypes={siteTypes ?? null}>
                      {socialOnly ? (
                        <SocialGuard>
                          <CustomLlstItem
                            key={index}
                            scope={scope}
                            subheader={subheader}
                            label={label}
                            onClick={onClick}
                            index={index}
                            value={value}
                            icon={icon}
                          />
                        </SocialGuard>
                      ) : (
                        <CustomLlstItem
                          key={index}
                          scope={scope}
                          subheader={subheader}
                          label={label}
                          onClick={onClick}
                          index={index}
                          value={value}
                          icon={icon}
                        />
                      )}
                    </SiteTypeGuard>
                  )
                )}
              </List>
            </PerfectScrollbar>
          </Drawer>
          <DrawerSpacer
            PaperProps={{
              style: { position: 'sticky', zIndex: -1, display: 'none' }
            }}
            ModalProps={{
              container: document.getElementById('drawer-container'),
              style: { position: 'sticky', zIndex: -1, display: 'none' }
            }}
            variant="permanent"
            open={open}
          />
        </>
      )}
      <Box
        sx={{
          flexGrow: 1,

          overflowX: 'hidden'
        }}
      >
        {children}
      </Box>
    </Box>
  );

  function CustomLlstItem({
    scope,
    subheader,
    label,
    onClick,
    index,
    value,
    icon
  }) {
    return (
      <ScopeGuard scope={scope}>
        <>
          {subheader ? (
            <ListSubheader
              sx={{
                backgroundColor: theme => theme?.palette.background.dark,
                py: 1
              }}
              disableGutters
              disableSticky
            >
              <Typography
                variant="subtitle2"
                sx={{
                  pl: 1,
                  color: theme => theme?.palette.text.primary,
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {label}
              </Typography>
            </ListSubheader>
          ) : (
            <ListItem
              onClick={onClick}
              key={index}
              disablePadding
              sx={activeValue == value ? activeStyle : null}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5
                }}
                title={label}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  <Iconify
                    icon={icon}
                    sx={activeValue == value ? activeStyle : null}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    style: {
                      // fontSize: 12,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                  }}
                  primary={label}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          )}
        </>
      </ScopeGuard>
    );
  }
}
