/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: { oid: 0, name: 'LIGHT' },
  ONE_DARK: { oid: 1, name: 'ONE_DARK' },
  UNICORN: { oid: 2, name: 'UNICORN' }
};

export const LanguagesEnum = {
  EN: { value: 0, label: 'English', direction: 'ltr', lang: 'en', flag: 'gb' },
  IW: { value: 1, label: 'עברית', direction: 'rtl', lang: 'iw', flag: 'il' },
  RU: { value: 2, label: 'Русский', direction: 'ltr', lang: 'ru', flag: 'ru' },
  ES: { value: 3, label: 'Español', direction: 'ltr', lang: 'es', flag: 'es' },
  SE: { value: 4, label: 'Svenska', direction: 'ltr', lang: 'se', flag: 'se' }
};

// recieves 'en' or 'iw'
export const getLanguageByKey = languageKey =>
  LanguagesEnum[languageKey?.toUpperCase()];

export const getLanguageByValue = languageValue => {
  let result;
  for (const lang in LanguagesEnum) {
    if (LanguagesEnum[lang].value === languageValue) {
      result = LanguagesEnum[lang];
    }
  }
  return result;
};
