const userErrorMessageEnum = {
  emailRequired: 'Email is Required',
  firstNameRequired: 'First name is Required',
  enterValidEmail: 'Must be a valid email',
  passwordRequired: 'Password is Required',
  lastNameRequired: 'Last name is Required',
  nameRequired: 'Name is Required',
  companyNameRequired: 'Company name is Required',
  companyNameValid:
    'Company name should only consist of lowercase letters and numbers.',
  mustBeChecked: 'This field must be checked',
  passwordsMustMatch: 'Both passwords must be the same',
  minPassLengthMessage:
    'You have entered an invalid password. Please use 10-20 characters.',
  unpredictedError:
    'Oops, something went wrong. please try again in a few minutes',
  atMaintenance:
    'We are doing a quick upgrade! This usually takes no more than 10 minutes, so please come back later.',
  jwtExpired: 'session is expired please login again',
  unauthorized: 'this page is restricted to logged in users only ',
  firstNameValidation:
    'First name can contain only letters (a-z), numbers (0-9), and periods (.) [with characters after] and 2-20 characters long',
  lastNameValidation:
    'Last name can contain only letters (a-z), numbers (0-9), and periods (.) [with characters after] and up to 20 characters long',
  invalidPasswordMessage:
    'Your password must have at least 1 alphabetic character, 1 numeric digit, 1 special character (!@#$%^&*()_+), and be between 10 and 20 characters long.',
  invalidEmailMessage: 'Invalid email',
  noChangesMade: 'No changes has been made.',
  invalidCharsAtName:
    "Name can contain only letters (a-z), numbers (0-9) and _ - ' ' @ .",
  emailsMustMatch: 'Both emails must be the same',
  contentCanNotBeEmpty: 'Content can not be Empty',
  deleteConfirmation: toDelete => `Are you sure you want to delete ${toDelete}`,
  varIsRequired: variableName => `${variableName} is Required`,
  varLengthMustBeSmallerThen: (variableName, maxLength) =>
    `${variableName} must be at most ${maxLength} characters`,
  hexColor: 'Color must contain only HexDecimal characters and start with #',
  searchMustBeLongerThen3: 'Search phrase must contain at least 3 characters',
  sendEmailIgnoreFileErrors:
    'Note: attached files has some errors. Would you like to send this email without those files?',
  toContactsCantBeEmpty: isReply =>
    isReply
      ? 'To contacts cant be empty.'
      : 'Please specify at least one recipient.'
};

export default userErrorMessageEnum;
