import { useRef, useState } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

import { Box, Button, Slider, Typography } from '@mui/material';
import 'react-image-crop/dist/ReactCrop.css';
import { useIntl } from 'react-intl';

function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
  defaultCropWidth,
  defaultCropHeight
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: defaultCropWidth,
        height: defaultCropHeight
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCrop({
  imgSrc,
  handleSave,
  aspect = 16 / 9,
  maxHeight,
  maxWidth,
  defaultCropWidth = 200,
  defaultCropHeight = 30
}) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const intl = useIntl();

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };
  const handleRotateChange = (event, newValue) => {
    setRotate(newValue);
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(
        centerAspectCrop(
          width,
          height,
          maxWidth / maxHeight,
          defaultCropWidth,
          defaultCropHeight
        )
      );
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }
    canvasToFile(previewCanvasRef.current, 'myFile.png', 'image/png');
  }

  function canvasToFile(canvas, fileName, fileType) {
    // Convert canvas to blob
    canvas.toBlob(function(blob) {
      // Create a new File object from the blob
      const file = new File([blob], fileName, { type: 'image/png' });
      handleSave(file);
    }, 'image/png');
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <div className="App">
      <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2 }}>
        <Typography gutterBottom>
          {intl.formatMessage({ id: 'scale' })}:
        </Typography>
        <Slider
          sx={{ width: 200 }}
          label="scale"
          size="small"
          defaultValue={1}
          min={0}
          max={10}
          step={0.1}
          value={scale}
          onChange={handleScaleChange}
          aria-label="Small"
          valueLabelDisplay="auto"
        />
        <Typography gutterBottom>
          {intl.formatMessage({ id: 'rotate' })}:
        </Typography>
        <Slider
          sx={{ width: 200 }}
          label="scale"
          size="small"
          defaultValue={0}
          min={-180}
          max={180}
          step={0.1}
          value={rotate}
          onChange={handleRotateChange}
          aria-label="Small"
          valueLabelDisplay="auto"
        />
      </Box>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={c => setCompletedCrop(c)}
          // aspect={aspect}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height
              }}
            />
          </div>
          <div>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={onDownloadCropClick}
            >
              {intl.formatMessage({ id: 'crop' })}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
