import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { accountSelector } from 'src/selectors/selectors';
import authService from '../../services/authService';

function DomainLoginGuard({ children }) {
  const account = useSelector(accountSelector);
  const { isServerDown, isUnauthorized } = useSelector(
    state => state.userInterface
  );

  const accessToken = authService.getAccessToken();
  const accessTokenValid = !!accessToken && accessToken !== '';

  if (accessTokenValid && account.user && !isServerDown && !isUnauthorized) {
    return <Redirect to="/app/reports/dashboard" />;
  }
  return children;
}

DomainLoginGuard.propTypes = {
  children: PropTypes.any
};

export default DomainLoginGuard;
