import { useEffect } from 'react';
import { isDevMode } from 'src/utils/utils';

export default function useChatScript() {
  useEffect(() => {
    if (isDevMode()) return;

    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.setAttribute('src', `//fw-cdn.com/11044637/3785516.js`);
    script.setAttribute('chat', 'false');

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);
}
