import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getCompanyInitialData } from 'src/actions/configActions';
import authService from 'src/services/authService';
import { logout, setUserData } from '../actions/userActions';

function Auth({ children }) {
  const dispatch = useDispatch();
  // const [isLoading, setLoading] = useState(true);
  const { search: urlToken } = useLocation();
  const tokenIndex = urlToken.indexOf('token=');
  const { isLoading } = useSelector(state => state.userInterface);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });
      if (tokenIndex === -1) {
        authService.handleAuthentication();

        if (authService.isAuthenticated()) {
          const user = await authService.loginInWithToken();
          await dispatch(setUserData(user));
          await dispatch(getCompanyInitialData());
        }
      } else {
        dispatch(logout());
      }
      // dispatch(setLoadingMode(false));
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    // return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
