import produce from 'immer';
import {
  CONFIG_DOMAIN_NAME,
  CONFIG_INITIALIZE,
  CONFIG_IS_MOBILE_NAV_OPEN
} from '../actions/configActions';
import serverDetails from '../utils/serverSettings';

const initialState = {
  domain: {
    affiliateDomain: false,
    affiliateNetworkManagerDomain: false,
    domainHost: serverDetails.path,
    domainType: 0,
    domainAlias: 'Affiliate Platform',
    mainDomain: true,
    name: serverDetails.domainName,
    subDomain: ''
  },
  isMobileNavOpen: false
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_INITIALIZE: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.domain = action.payload.domain;
      });
    }
    case CONFIG_DOMAIN_NAME: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.domain.domainAlias = action.payload;
      });
    }
    case CONFIG_IS_MOBILE_NAV_OPEN: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.isMobileNavOpen = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};

export default configReducer;
