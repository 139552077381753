import { makeStyles } from '@mui/styles';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #ccc!important',
    '& .rdw-option-wrapper': {
      background: 'transparent',
      border: 'none',
      minWidth: 26,
      padding: 6,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.action.hover
      }
    },
    '& .rdw-option-active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.action.selected
    },
    '& .rdw-dropdown-wrapper': {
      flip: false,
      direction: 'ltr !important',
      boxShadow: 'none',
      background: 'transparent'
    },
    '& .rdw-dropdown-selectedtext': {
      color: 'black'
    },
    '& .rdw-dropdown-optionwrapper': {
      overflowY: 'auto',
      boxShadow: theme.shadows[10],
      padding: theme.spacing(1),
      color: 'black'
    }
  },
  toolbar: {
    marginBottom: 0,
    borderLeft: 'none',
    borderTop: 'none',
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: 'transparent'
  },
  editor: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary
  }
}));

const WYSIWYGEditor = ({ onChange, value }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : '';
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value]);

  const onEditorStateChange = editorState => {
    setUpdated(true);
    setEditorState(editorState);

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <React.Fragment>
      <div className="editor">
        <Editor
          spellCheck
          editorState={editorState}
          wrapperClassName={classes.root}
          toolbarClassName={classes.toolbar}
          editorClassName={classes.editor}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
    </React.Fragment>
  );
};

export default WYSIWYGEditor;
