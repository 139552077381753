/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListSubheader,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { SiteTypeEnum } from 'src/components/guards/BrokerGuard';
import ScopeGuard from 'src/components/guards/ScopeGuard';
import Iconify from 'src/components/iconify/Iconify';
import { accountSelector, companySelector } from 'src/selectors/selectors';
import { UserPermissionsEnum } from 'src/utils/enums/UserPermissionsEnum';
import userProfileImageSize from '../../../utils/enums/userProfileImageSize';
import getUserProfileImage from '../../../utils/filesUploadUtils/getUserProfileImage';
import MembershipInfo from './MembershipInfo';
import NavItem from './NavItem';

const PREFIX = 'index';

const classes = {
  mobileDrawer: `${PREFIX}-mobileDrawer`,
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  avatar: `${PREFIX}-avatar`,
  navbarLayout: `${PREFIX}-navbarLayout`,
  navbarFooter: `${PREFIX}-navbarFooter`,
  docksBox: `${PREFIX}-docksBox`,
  checkOurDocks: `${PREFIX}-checkOurDocks`,
  filterButton: `${PREFIX}-filterButton`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.mobileDrawer}`]: {
    width: 256
  },

  [`& .${classes.desktopDrawer}`]: {
    width: 245,
    top: theme.direction == 'rtl' ? 65 : 50,
    height: `calc(100% - ${theme.direction == 'rtl' ? 65 : 50}px)`
  },

  [`& .${classes.avatar}`]: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },

  [`& .${classes.navbarLayout}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  [`& .${classes.navbarFooter}`]: {
    margin: '17px',
    paddingLeft: '21px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '7px'
  },

  [`& .${classes.docksBox}`]: {
    margin: '17px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },

  [`& .${classes.checkOurDocks}`]: {
    color: '#8492a6',
    padding: '7px 0px'
  }
}));

export function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items?.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            ...rest
          }),
        []
      )}
    </List>
  );
}

function getAllowedTypes(item) {
  const allowedTypesSet = new Set();

  for (const subItem of item.items) {
    if (subItem.allowedTypes === undefined) {
      //at least 1 go to def value
      return Object.values(SiteTypeEnum);
    } else {
      subItem.allowedTypes.forEach(type => allowedTypesSet.add(type));
    }
  }

  return [...allowedTypesSet];
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;
  const open =
    Boolean(
      matchPath(pathname, {
        path: item.href || (item.inlineIcon && item.inlineIcon.href),
        exact: false
      })
    ) ||
    Boolean(
      item?.items?.find(i =>
        Boolean(
          matchPath(pathname, {
            path: i.href || (i.inlineIcon && i.inlineIcon.href),
            exact: false
          })
        )
      )
    );

  if (item.items) {
    acc.push(
      <ScopeGuard scope={item?.scope} key={nanoid()}>
        <NavItem
          depth={depth}
          icon={item.icon}
          isBeta={item?.beta}
          key={key}
          info={item.info}
          open={Boolean(open)}
          title={item.title}
          onClick={item.onClick}
          inlineIcon={item.inlineIcon}
          toolTipText={item.toolTipText}
          childItemsSize={item.items.length}
          allowedTypes={getAllowedTypes(item)} // Set allowedTypes to include all allowed types from sub-items without duplicates
          notification={item?.items.reduce((sum, obj) => {
            return sum + (obj?.notification || 0); // Use 0 if notification is undefined or null
          }, 0)}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      </ScopeGuard>
    );
  } else {
    acc.push(
      <ScopeGuard key={nanoid()} scope={item?.scope}>
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          isBeta={item?.beta}
          key={key}
          open={Boolean(open)}
          info={item.info}
          title={item.title}
          onClick={item.onClick}
          inlineIcon={item.inlineIcon}
          notification={item?.notification}
          toolTipText={item.toolTipText}
          allowedTypes={item?.allowedTypes}
        />
      </ScopeGuard>
    );
  }

  return acc;
}

let counter = 0;

function NavBar({ openMobile, onMobileClose, intl }) {
  const {
    notification,
    socialMode,
    onboardingDone,
    siteType,
    hidePPCCommission,
    hideCPMCommission
  } = useSelector(companySelector);
  const navConfig = useMemo(() => {
    const groupings = [
      {
        title: intl.formatMessage({ id: 'countries' }),
        icon: () => <Iconify icon="gis:search-country" />,
        href: '/app/groupings/countries'
      },
      {
        title: intl.formatMessage({ id: 'symbols' }),
        icon: () => (
          <Iconify icon="streamline:money-currency-dollar-dollar-exchange-payment-forex-finance-currency-money-foreign" />
        ),
        href: '/app/groupings/symbols',
        allowedTypes: [SiteTypeEnum.BROKER, SiteTypeEnum.CASINO]
      }
    ];
    const home = [
      {
        title: intl.formatMessage({ id: 'dashboard' }),
        icon: () => <Iconify icon="material-symbols:dashboard-outline" />,
        href: '/app/reports/dashboard'
      },

      {
        title: intl.formatMessage({ id: 'news' }),
        icon: () => <Iconify icon="fluent:news-20-regular" />,
        href: '/app/management/news'
      }
    ];
    const management = [
      {
        title: intl.formatMessage({ id: 'advertisers' }),
        icon: () => <Iconify icon="mdi:partnership-outline" />,
        items: [
          {
            title: intl.formatMessage({ id: 'advertisers' }),
            icon: () => <Iconify icon="mdi:partnership-outline" />,
            href: '/app/management/advertisers',
            scope: UserPermissionsEnum.ADVERTISER.value
          },
          {
            title: intl.formatMessage({ id: 'registrations' }),
            icon: () => <Iconify icon="mingcute:user-question-line" />,
            href: '/app/management/advertisers/registrations',
            notification: notification?.advertisersNeedsApproval,
            scope: UserPermissionsEnum.ADVERTISER.value
          },
          {
            title: intl.formatMessage({ id: 'payouts' }),
            icon: () => <Iconify icon="material-symbols:payments-outline" />,
            href: '/app/management/payouts',
            scope: UserPermissionsEnum.PAYOUTS.value
          }
        ],
        scope: UserPermissionsEnum.ADVERTISER.value
      },
      {
        title: intl.formatMessage({ id: 'campaigns' }),
        icon: () => <Iconify icon="ic:outline-campaign" />,
        href: '/app/management/campaigns',
        scope: UserPermissionsEnum.AFFILIATE_CAMPAIGN.value
      },

      {
        title: intl.formatMessage({ id: 'statistics' }),
        icon: () => <Iconify icon="iconoir:stats-up-square" />,
        href: '/app/management/statistics',
        scope: UserPermissionsEnum.STATISTICS.value,
        items: [
          {
            title: intl.formatMessage({ id: 'conversions' }),
            icon: () => (
              <Iconify icon="material-symbols:connect-without-contact" />
            ),
            href: '/app/management/statistics/conversions',
            scope: UserPermissionsEnum.CONVERSIONS.value
          },
          {
            title: intl.formatMessage({ id: 'leads' }),
            icon: () => <Iconify icon="mdi:leads" />,
            href: '/app/management/statistics/leads',
            scope: UserPermissionsEnum.LEADS.value
          },
          {
            title: intl.formatMessage({ id: 'impressions' }),
            icon: () => <Iconify icon="carbon:view" />,
            href: '/app/management/statistics/impressions',
            scope: UserPermissionsEnum.IMPRESSIONS.value,
            delete: hideCPMCommission
          },
          {
            title: intl.formatMessage({ id: 'clicks' }) + ' (PPC)',
            icon: () => <Iconify icon="ic:baseline-ads-click" />,
            href: '/app/management/statistics/clicks',
            scope: UserPermissionsEnum.CLICKS.value,
            delete: hidePPCCommission
          },
          {
            title: intl.formatMessage({ id: 'reports' }),
            icon: () => <Iconify icon="material-symbols:campaign" />,
            href: '/app/management/statistics/campaigns',
            scope: UserPermissionsEnum.CONVERSIONS.value
          },
          {
            title: intl.formatMessage({ id: 'igaming_reports' }),
            icon: () => <Iconify icon="material-symbols:campaign" />,
            href: '/app/management/statistics/igaming-reports',
            scope: UserPermissionsEnum.CONVERSIONS.value,
            allowedTypes: [SiteTypeEnum.IGAMING_NETWORK]
          }
        ].filter(item => !item?.delete)
      },
      {
        title: intl.formatMessage({ id: 'forex' }),
        icon: () => <Iconify icon="ant-design:stock-outlined" />,
        href: '/app/management/finance',
        scope: UserPermissionsEnum.FINANCIAL.value,
        items: [
          {
            title: intl.formatMessage({ id: 'users' }),
            icon: () => <Iconify icon="solar:users-group-two-rounded-bold" />,
            href: '/app/management/finance/users',
            scope: UserPermissionsEnum.FINANCIAL_USERS.value,
            allowedTypes: [SiteTypeEnum.BROKER, SiteTypeEnum.CASINO]
          },
          {
            title: intl.formatMessage({ id: 'accounts' }),
            icon: () => <Iconify icon="carbon:view" />,
            href: '/app/management/finance/accounts',
            scope: UserPermissionsEnum.FINANCIAL_ACCOUNTS.value,
            allowedTypes: [SiteTypeEnum.BROKER]
          },
          {
            title: intl.formatMessage({ id: 'transactions' }),
            icon: () => <Iconify icon="ic:baseline-ads-click" />,
            href: '/app/management/finance/transactions',
            scope: UserPermissionsEnum.FINANCIAL_TRANSACTIONS.value,
            allowedTypes: [SiteTypeEnum.BROKER, SiteTypeEnum.CASINO]
          },
          {
            title: intl.formatMessage({ id: 'reports' }),
            // beta: true,
            icon: () => <Iconify icon="material-symbols:campaign" />,
            href: '/app/management/statistics/financial',
            scope: UserPermissionsEnum.FINANCIAL_REPORTS.value,
            allowedTypes: [SiteTypeEnum.BROKER, SiteTypeEnum.CASINO]
          }
        ]
      },
      {
        title: intl.formatMessage({ id: 'affiliates' }),
        icon: () => (
          <Iconify icon="material-symbols:partner-exchange-rounded" />
        ),
        items: [
          {
            title: intl.formatMessage({ id: 'payouts' }),
            icon: () => (
              <Iconify icon="streamline:money-cash-bag-dollar-bag-payment-cash-money-finance" />
            ),
            notification: notification?.affiliatePayout,
            href: '/app/management/affiliates/payouts-requests',
            scope: UserPermissionsEnum.AFFILIATE_PAYOUT.value
          },
          {
            title: intl.formatMessage({ id: 'requests' }),
            icon: () => (
              <Iconify icon="material-symbols:request-page-outline" />
            ),
            notification: notification?.requestToJoinCampaign,
            href: '/app/management/affiliates/campaign-requests',
            scope: UserPermissionsEnum.AFFILIATE_REQUESTS.value
          },
          {
            notification: notification?.affiliatesNeedsApproval,
            title: intl.formatMessage({ id: 'affiliate_management' }),
            icon: () => <Iconify icon="fa6-solid:users-gear" />,
            href: '/app/management/affiliate-management',
            scope: UserPermissionsEnum.AFFILIATE_MANAGEMENT.value
          },
          {
            title: intl.formatMessage({ id: 'lifetime_affiliates' }),
            icon: () => <Iconify icon="mdi:forever" />,
            href: '/app/management/lifetime-affiliates',
            scope: UserPermissionsEnum.AFFILIATE_MANAGEMENT.value,
            allowedTypes: [SiteTypeEnum.GENERAL]
          },
          {
            title: intl.formatMessage({ id: 'balance_sheet' }),
            icon: () => <Iconify icon="icon-park-outline:transaction" />,
            href: '/app/management/affiliates/balance',
            scope: UserPermissionsEnum.AFFILIATE_MANAGEMENT.value
          }
        ]
      },
      {
        title: intl.formatMessage({ id: 'grouping' }),
        icon: () => <Iconify icon="clarity:blocks-group-solid-badged" />,
        items: groupings,
        scope: UserPermissionsEnum.GROUPING.value
      },
      {
        title: intl.formatMessage({ id: 'logs' }),
        icon: () => <Iconify icon="arcticons:nrf-logger" />,
        href: '/app/management/statistics',
        scope: UserPermissionsEnum.LOGS.value,
        items: [
          {
            title: intl.formatMessage({ id: 'clicks' }),
            icon: () => <Iconify icon="material-symbols:campaign" />,
            href: '/app/management/statistics/campaignLogs',
            scope: UserPermissionsEnum.CLICK_LOGS.value
          },
          {
            title: intl.formatMessage({ id: 'postback_stats' }),
            icon: () => <Iconify icon="octicon:log-16" />,
            href: '/app/management/statistics/postback',
            scope: UserPermissionsEnum.POSTBACK_LOGS.value
          },
          {
            title: intl.formatMessage({ id: 'audit' }),
            icon: () => <Iconify icon="carbon:user-activity" />,
            href: '/app/management/audit',
            scope: UserPermissionsEnum.AUDIT.value
          },
          {
            title: intl.formatMessage({ id: 'webhook_logs' }),
            icon: () => <Iconify icon="carbon:user-activity" />,
            href: '/app/management/logs/webhooks',
            scope: UserPermissionsEnum.AUDIT.value
          }
        ]
      },
      {
        title: intl.formatMessage({ id: 'settings' }),
        icon: () => <Iconify icon="material-symbols:settings-outline" />,
        href: '/app/management/settings'
      },
      {
        title: intl.formatMessage({ id: 'integrations' }),
        icon: () => (
          <Iconify icon="material-symbols-light:integration-instructions-outline" />
        ),
        href: '/app/management/integrations',
        scope: UserPermissionsEnum.SETTINGS.value
      }
      // {
      //   title: intl.formatMessage({ id: 'billing' }),
      //   icon: () => <Iconify icon="material-symbols:shopping-cart-outline" />,
      //   href: '/app/management/billing',
      //   scope: UserPermissionsEnum.BILLING.value
      // },
      // {
      //   title: intl.formatMessage({ id: 'contact_us' }),
      //   icon: () => <Iconify icon="material-symbols:contact-support-outline" />,
      //   href: '/app/management/contact-us'
      // }
    ];
    if (socialMode) {
      home.splice(1, 0, {
        title: intl.formatMessage({ id: 'social' }),
        icon: () => <Iconify icon="iconoir:internet" />,
        href: '/app/reports/dashboard/social'
      });
    }
    if (!onboardingDone) {
      home.splice(0, 0, {
        title: intl.formatMessage({ id: 'getting_started' }),
        icon: () => <Iconify icon="carbon:rocket" />,
        href: '/app/welcome',
        scope: UserPermissionsEnum.SETTINGS.value
      });
    }

    return [
      {
        // subheader: intl.formatMessage({ id: 'home' }),
        items: home.concat(management)
      }
      // {
      //   subheader: intl.formatMessage({ id: 'management' }),
      //   items: management
      // }
    ];
  }, [intl, onboardingDone, siteType]);

  const location = useLocation();
  const { user, avatarChanged } = useSelector(accountSelector);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const [imgUrl, setImgUrl] = useState('');

  const updateUserImage = async () => {
    await getUserProfileImage(user, userProfileImageSize.medium, setImgUrl);
  };

  if (avatarChanged && counter < 1) {
    updateUserImage();
    counter++;
  }
  if (user.profileImage === null) {
    if (imgUrl !== '') {
      setImgUrl('');
    }
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box className={classes.navbarLayout}>
          <Box p={1} justifyContent="flex-start">
            <Box p={2}>
              <Box mt={2} textAlign="center">
                <Typography variant="h4" color="textPrimary">
                  {user?.firstName + ' ' + user?.lastName || '-'}
                </Typography>
                <MembershipInfo />
              </Box>
            </Box>
            <Divider />
            {navConfig.map((config, i) => (
              <List
                key={i}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                    {config.inlineIcon && (
                      <Tooltip placement="top" title="Add Team">
                        <IconButton
                          onClick={handleTeamAddClick}
                          style={{ float: 'right' }}
                          size="large"
                        >
                          <config.inlineIcon.icon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Root>
      <Hidden lgUp>
        <Drawer
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </Root>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default injectIntl(NavBar);
