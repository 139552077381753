import { Alert, Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ScopeGuard from 'src/components/guards/ScopeGuard';
import { companySelector } from 'src/selectors/selectors';
import { UserPermissionsEnum } from 'src/utils/enums/UserPermissionsEnum';
import { getUtcDate } from 'src/utils/utils';

export default function MembershipBanner(props) {
  const { membership } = useSelector(companySelector);
  const intl = useIntl();
  const history = useHistory();

  return (
    membership?.type == 'FREE' && (
      <>
        <ScopeGuard scope={UserPermissionsEnum.BILLING.value}>
          <Alert
            severity="warning"
            action={
              <Button
                onClick={() => history.push('/app/management/billing')}
                color="inherit"
                size="small"
              >
                {intl.formatMessage({ id: 'upgrade' })}
              </Button>
            }
          >
            {intl.formatMessage({ id: 'free_trial_ending' }, [
              getUtcDate(membership?.expirationDate).fromNow()
            ])}
          </Alert>
        </ScopeGuard>
      </>
    )
  );
}
