import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import 'src/assets/css/generalOnly.css';
import { use2FADialog } from 'src/providers/TwoFactorProvider';
import {
  INVALID_2FA_TOKEN,
  MISSING_2FA_TOKEN,
  NO_PERMISSION
} from 'src/utils/staticObjects';
import { displayUpdateSnackbar } from 'src/utils/utils';
import { logout } from '../actions/userActions';
import { setLoadingMode } from '../actions/userInterfaceActions';
import networkErrorMessageEnum from '../utils/enums/networkErrorMessageEnum';
import userErrorMessageEnum from '../utils/enums/userErrorMessageEnum';

let setModalStateExternal;
// eslint-disable-next-line import/no-mutable-exports
export let publicRedirectToHome = null;

/*
 * get custom message from external components
 */
export const openCustomMessage = messageToShow => {
  setModalStateExternal(messageToShow);
};
const modalStateInitialState = {
  message: '',
  onClose: () => null
};
let isDialogShown = false;

function GlobalErrorModal() {
  const [modalState, setModalState] = useState(modalStateInitialState);
  const dispatch = useDispatch();
  const showDialog = use2FADialog();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const clearToken = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('persist:root');
    delete axios.defaults.headers.common.Authorization;
    dispatch(logout());
  };

  const clearModal = () => {
    setModalState(modalStateInitialState);
    dispatch(setLoadingMode(false));
    isDialogShown = false;
  };
  const redirectToHome = (isClearToken = true) => {
    clearModal();

    if (isClearToken) {
      clearToken();
      document.location.replace('/login');
    }
  };

  axios.interceptors.response.use(
    response => response,
    error => {
      console.log(error);
      if (error.response && error.response.status === 423) {
        throw error;
      }
      // if (error.response && error.response.status === 403) {
      //   // setModalState({
      //   //   message: error.response.data.message,
      //   //   onClose: clearModal
      //   // });
      //   // this prevents from getting inside the then() and catch() blocks
      //   return new Promise(() => {});
      // }
      if (error.response && error.response.status === 401) {
        const msg = error.response?.data?.message;
        if (msg == MISSING_2FA_TOKEN) {
          return showDialog({
            originalRequest: error.config
          });
        }
        if (msg == NO_PERMISSION) {
          displayUpdateSnackbar(enqueueSnackbar, intl, false, NO_PERMISSION);
          return new Promise(() => {});
        }

        if (msg == INVALID_2FA_TOKEN) {
          return showDialog({
            originalRequest: error.config,
            message: <FormattedMessage id={msg} />
          });
        }
        redirectToHome();
        return new Promise(() => {});
      }
      if (
        error.message &&
        (error.message === networkErrorMessageEnum.networkError ||
          !error.response)
      ) {
        if (!isDialogShown) {
          isDialogShown = true;
        }
        setModalState({
          message: userErrorMessageEnum.atMaintenance,
          onClose: () => redirectToHome(false)
        });
      }
      throw error;
    }
  );
  // init params that need to be initialized before printing the page
  useLayoutEffect(() => {
    // pointer to top level message state to be exported
    setModalStateExternal = setModalState;
    // pointer to top level method to be exported
    publicRedirectToHome = redirectToHome;
  }, []);
  return modalState.message ? (
    <Dialog
      style={{ zIndex: '9999999' }}
      open={!!modalState.message}
      keepMounted
      onClose={modalState.onClose}
      aria-labelledby="globalErrorModalTitle"
    >
      <DialogTitle id="globalErrorModalTitle">Error</DialogTitle>
      <DialogContent>
        <DialogContentText>{modalState.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={modalState.onClose}>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

export default GlobalErrorModal;
