import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CONFIG_IS_MOBILE_NAV_OPEN } from '../../actions/configActions';
import NavBar from './NavBar';
import TopBar from './TopBar';
import MembershipBanner from './TopBar/MembershipBanner';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 65,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 245
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    flexDirection: 'column'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ children }) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleMobileNavChange = isMobileNavOpen => {
    dispatch({
      type: CONFIG_IS_MOBILE_NAV_OPEN,
      payload: isMobileNavOpen
    });
    setMobileNavOpen(isMobileNavOpen);
  };

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => handleMobileNavChange(true)} />
      <NavBar
        onMobileClose={() => handleMobileNavChange(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <MembershipBanner />
          <div
            id="drawer-container"
            style={{ position: 'relative' }}
            className={classes.content}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
