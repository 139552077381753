import { Button, Grid, Typography } from '@mui/material';
import { clearCookies } from './utils/utils';
import useSettings from './hooks/useSettings';
import { THEMES } from './constants';

function ErrorBoundaryFallback() {
  const settings = useSettings();
  const defaultLogo = `/static/logos/tracknow-logo-${
    settings.settings?.theme === THEMES.LIGHT.name ? 'dark' : 'white'
  }.webp`;
  const handleRefresh = () => {
    clearCookies();
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Grid
      sx={{
        backgroundColor: theme => theme?.palette?.background?.dark,
        minHeight: window.innerHeight,
        marginTop: 0
      }}
      container
      direction="column"
      alignItems="center"
      spacing={3}
    >
      <Grid item mt={6}>
        <img
          alt="Logo"
          src={defaultLogo}
          style={{ maxWidth: 200, maxHeight: 50 }}
        />
      </Grid>
      <Grid item mt={1}>
        <Typography align="center" variant={'h1'} color="textPrimary">
          Unexpected Error
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          If this happens often please{' '}
          <a href={'https://www.tracknow.io/contact.html'}> contact us </a>
        </Typography>
      </Grid>
      <Grid item mt={6}>
        <Button
          color="secondary"
          onClick={() => (window.location.href = '/')}
          variant="outlined"
        >
          Back to home
        </Button>
      </Grid>
      <Grid item mt={1}>
        <Button color="primary" onClick={handleRefresh} variant="outlined">
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
}

export default ErrorBoundaryFallback;
