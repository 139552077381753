import TopBar from '../layouts/DashboardLayout/TopBar';
import { useMemo } from 'react';
import { isUrlDefaultDomain } from '../utils/utils';

function AuthPageTopBar() {
  const isDefaultDomain = useMemo(() => isUrlDefaultDomain(), []);

  return !isDefaultDomain ? (
    <TopBar onMobileNavOpen={() => true} centerLogo />
  ) : null;
}

export default AuthPageTopBar;
