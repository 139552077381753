/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CLOSE_NEW_MESSAGE,
  CLOSE_NEW_REPLY_MESSAGE,
  DRAFT_SAVED,
  OPEN_NEW_MESSAGE,
  OPEN_NEW_REPLY_MESSAGE,
  PAGING_RESTED,
  RESET_PAGING,
  SERVER_DOWN,
  SET_IS_LOADING,
  UNAUTHORIZED_USER
} from '../actions/userInterfaceActions';

const initialState = {
  isOpenReplyMessage: false,
  newMessageOpen: false,
  draftComposeOpenFor: undefined,
  previousFolderName: '',
  isResetPaging: false,
  isServerDown: false,
  isUnauthorized: false,
  isLoading: true,
  isDraftSaved: false
};

const userInterfaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NEW_MESSAGE: {
      return produce(state, draft => {
        const { mailOid } = action.payload;
        draft.newMessageOpen = true;
        // pass this value only if we want open draft compose
        if (mailOid && mailOid !== 'new') {
          draft.draftComposeOpenFor = mailOid;
        }
        if (mailOid === 'new') {
          draft.draftComposeOpenFor = undefined;
        }
      });
    }
    case OPEN_NEW_REPLY_MESSAGE: {
      return produce(state, draft => {
        if (action.payload) {
          const { mailOid } = action.payload;
          // pass this value only if we want open draft compose
          if (mailOid) {
            draft.draftComposeOpenFor = mailOid;
          }
        }
        draft.isOpenReplyMessage = true;
      });
    }

    case CLOSE_NEW_REPLY_MESSAGE: {
      return produce(state, draft => {
        if (draft.isOpenReplyMessage) {
          draft.isOpenReplyMessage = false;
          draft.draftComposeOpenFor = undefined;
        }
      });
    }

    case CLOSE_NEW_MESSAGE: {
      return produce(state, draft => {
        draft.newMessageOpen = false;
        if (draft.draftComposeOpenFor !== undefined) {
          draft.draftComposeOpenFor = undefined;
        }
      });
    }

    case RESET_PAGING: {
      return produce(state, draft => {
        const { folderName } = action.payload;
        draft.isResetPaging =
          draft.previousFolderName === folderName ||
          draft.previousFolderName === '';
        draft.previousFolderName = folderName;
      });
    }

    case PAGING_RESTED: {
      return produce(state, draft => {
        draft.isResetPaging = false;
      });
    }
    case SERVER_DOWN: {
      return produce(state, draft => {
        draft.isServerDown = action.payload.status;
      });
    }
    case UNAUTHORIZED_USER: {
      return produce(state, draft => {
        draft.isUnauthorized = true;
      });
    }
    case SET_IS_LOADING: {
      return produce(state, draft => {
        const { loadingMode } = action.payload;
        draft.isLoading = loadingMode;
      });
    }
    case DRAFT_SAVED: {
      return produce(state, draft => {
        const { draftStatus } = action.payload;
        draft.isDraftSaved = draftStatus;
      });
    }

    default: {
      return state;
    }
  }
};

export default userInterfaceReducer;
